import React, { useEffect } from 'react';
import SidebarWrapper from '../components/SidebarWrapper';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { getLink } from '../util/linkUtils';
import NavBar from '../components/NavBar';
import { Quiz } from '../components/Quiz';

export const StaticResultsWrapper = (props: {
  pageTitle: string;
  items: {
    name: string;
    description: string;
    link: string | undefined;
    image: string | undefined;
  }[];
}) => {
  const { items, pageTitle } = props;
  useEffect(() => {
    PiwikPro.initialize(
      'aa4790e1-d8c1-4117-9d39-8bfc787a95f7',
      'https://falofofo.containers.piwik.pro'
    );
  }, []);

  return (
    <>
      <NavBar />
      <SidebarWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <p style={{ maxWidth: 800, width: '80%', marginTop: '2em' }}>
            <h1 style={{ fontFamily: 'Montserrat', marginBottom: '.5em' }}>{pageTitle}</h1>
            <h3 style={{ fontFamily: 'Montserrat', marginTop: '0' }}>
              Browse our gift ideas below, or use our
              <a href="#QuizDialog"> Customized Gift Search</a>
            </h3>
            {items.map((item) => {
              return (
                <p key={item.name}>
                  <a
                    href={getLink(item)}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}>
                    <h4 style={{ fontFamily: '"Press Start 2P"' }}>{item.name}</h4>
                  </a>
                  <h3 style={{ fontFamily: 'Montserrat', fontWeight: '200' }}>
                    {item.description}
                  </h3>
                  {item.image && <img src={item.image} />}
                </p>
              );
            })}
            <h4 style={{ fontFamily: '"Press Start 2P"', marginBottom: '-70px' }}>
              Didn&apos;t find what you are looking for? Try a custom AI Gift Search
            </h4>
          </p>
        </div>
        <Quiz />
      </SidebarWrapper>
    </>
  );
};
