/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { HeadFC } from 'gatsby';
import { Amplify } from 'aws-amplify';
// @ts-ignore
import Seo from '../components/seo';
// @ts-ignore
import config from '../aws-exports';
import { StaticResultsWrapper } from '../components/StaticResultsWrapper';

let isLocalhost;
let isFeat;
let devRedirect;
if (typeof window !== 'undefined') {
  isLocalhost = !!(window.location.hostname === 'localhost');
  isFeat = !!(window.location.hostname === 'feature.d2b2yr0jqmp0n5.amplifyapp.com');
  devRedirect = isLocalhost ? 'http://localhost:8000/' : undefined;
  devRedirect = isFeat ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/' : devRedirect;
}
// use correct URI in the right env
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: devRedirect ? devRedirect : 'https://www.findgiftsfor.com/',
    redirectSignOut: devRedirect
      ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/'
      : config.oauth.redirectSignOut // include localhost?
  }
};

Amplify.configure(updatedAwsConfig);

type DataProps = {
  site: {
    buildTime: string;
  };
};

const pageTitle = "Father's Day Gift Ideas 2023";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const items: {
  name: string;
  description: string;
  link: string | undefined;
  image: string | undefined;
}[] = require('../searchResults/fathersday.json');

export const FathersDayPage = () => <StaticResultsWrapper pageTitle={pageTitle} items={items} />;

export const Head: HeadFC<DataProps> = () => (
  <Seo title="Father's Day Gift Ideas 2023 - FindGiftsFor ChatGPT Gift Search" />
);

export default FathersDayPage;
